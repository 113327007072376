import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {Observable, map, of} from 'rxjs';
import {Subscriber} from 'app/shared/models/entity/subscriber';
import _ from 'lodash';
import {GraphqlService} from "../services/graphql.service";

@Injectable()
export class ClientExpiredLicenseGuard {
  constructor(private router: Router, private authService: AuthService, private graphqlService: GraphqlService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let obs: Observable<Subscriber>;
    if (!_.isNil(this.authService.activeSubscriberSnapshot)) {
      obs = of(this.authService.activeSubscriberSnapshot);
    } else {
      obs = this.graphqlService.queries.entity.account.activeSubscriber();
    }

    return obs.pipe(
      map((subscriber) => {
        if ((subscriber.activeLicenses ?? []).length <= 0) {
          this.router.navigate(['client/setting/license']);
          return false;
        } else {
          return true;
        }
      })
    );
  }
}
